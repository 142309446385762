/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Tabs, Button, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { maskPhoneNumber } from 'components/common/PhoneNumberMask';
import { store } from 'redux/store';
import { axiosInstance } from 'services/axios';

const { TabPane } = Tabs;

const LeadHistoryTabs = (props) => {
  const { userReducer } = store.getState();
  const userPermissions = userReducer?.userDetails?.permissions?.map(permission => permission.codename);

  return (
    <Tabs defaultActiveKey="1" onChange={props?.handleTabChange}>
      <TabPane tab="Details" key="1">
        <CustomerDetails
          customer={props?.customer}
          handleCustomerEdit={props.handleCustomerEdit}
          isLoading={props?.isLoading}
        />
      </TabPane>
      {userPermissions?.includes('view_schedule') && (
        <TabPane tab="Schedule" key="2">
          <ScheduleDetails
            schedule={props?.schedule}
            loadingSchedule={props?.loadingSchedule}
            handleCustomerEdit={props.handleCustomerEdit}
          />
        </TabPane>
      )}
      {props?.leadType === 'rejoining' && (
        <TabPane tab="Driver Details" key="3">
          <DriverDetails
            driver={props?.driver}
            isLoadingDriver={props?.isLoadingDriver}
          />
        </TabPane>
      )}
    </Tabs>
  );
};

const CustomerDetails = ({ customer, handleCustomerEdit, isLoading }) => {
  const isCustomerEmpty = !customer || Object.keys(customer).length === 0;

  if (isLoading) {
    return (
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      {!isCustomerEmpty ? <fieldset style={{ padding: "10px", backgroundColor: "#006868" }} id='customerDetails' >
        <div className='row'>
          <div className='col-md-8'>
            <div>
              <b>Name: </b>
              <span>{`${customer?.first_name} ${customer?.last_name}`}</span>
            </div>
            <div>
              <b>Phone: </b>
              <span>{maskPhoneNumber(customer?.phone)}</span>
            </div>
            {customer?.location &&
              <div>
                <b>Location: </b>
                <span>{customer?.location}</span>
              </div>
            }
            <div>
              <b>City: </b>
              <span>{customer?.city?.name}</span>
            </div>
            <div>
              <b>Source: </b>
              <span>{customer?.lead_source?.label}</span>
            </div>
            <div>
              <b>Sub Source: </b>
              <span>{customer?.sub_source?.label}</span>
            </div>
          </div>
          <div className='col-md-4'>
            <Button title='Edit' type="default" shape="round" icon={<EditOutlined />} onClick={() => handleCustomerEdit(customer, 'customer')} style={{ left: '50%' }}></Button>
          </div>
        </div>
      </fieldset >
        :
        <fieldset className={'tabErrorNotification'}>
          <div className='row'>
            Customer details not found. The record will be created now and shown in subsequent calls.
          </div>
        </fieldset>}
    </>
  )
};

const ScheduleDetails = ({ schedule, loadingSchedule, handleCustomerEdit }) => {
  if (loadingSchedule) {
    return (
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Spin />
      </div>
    );
  }

  return (
    <fieldset style={{ padding: "10px", backgroundColor: "#006868" }} id='scheduleDetails'>
      <div className='row'>
        <div className='col-md-8'>
          <div>
            <b>Date: </b>
            <span>{schedule?.date}</span>
          </div>
          <div>
            <b>Time Slots: </b>
            <span>{schedule?.timeslot}</span>
          </div>
          <div>
            <b>Location / Hub: </b>
            <span>{schedule?.location_hub?.location}</span>
          </div>
          <div>
            <b>Vehicle Type: </b>
            <span>{schedule?.vehicle_type}</span>
          </div>
          <div>
            <b>Business Model: </b>
            <span>{schedule?.businessmodal?.name}</span>
          </div>
          {schedule?.remarks &&
            <div>
              <b>Remarks: </b>
              <span>{schedule?.remarks}</span>
            </div>
          }
        </div>
        <div className='col-md-4'>
          <Button
            title='Edit'
            type="default"
            shape="round"
            icon={<EditOutlined />}
            onClick={() => handleCustomerEdit(schedule, 'schedule')}
            style={{ left: '50%' }}
          >
          </Button>
        </div>
      </div>
    </fieldset>
  );
};

const DriverDetails = ({ driver, isLoadingDriver }) => {
  const isDriverEmpty = !driver || Object.keys(driver).length === 0;

  if (isLoadingDriver) {
    return (
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      {!isDriverEmpty ? <fieldset style={{ padding: "10px", backgroundColor: "#006868" }} id='driverDetails'>
        <div className='row'>
          <div className='col-md-8'>
            <div>
              <b>Employee Id: </b>
              <span>{driver?.hawkeye_employee_id}</span>
            </div>
          </div>
        </div>
      </fieldset>
        :
        <fieldset className={'tabErrorNotification'}>
          <div className='row'>
            Driver details not found. The record will be created now and shown in subsequent calls.
          </div>
        </fieldset>}
    </>
  )
};

export default LeadHistoryTabs;