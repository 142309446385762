/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Drawer, Form, Input, Modal, notification, Select, Space, Spin } from 'antd';
import { CustomerServiceOutlined, CloseOutlined, LoginOutlined, MinusOutlined, EditOutlined, PlusOutlined, HistoryOutlined, CheckOutlined } from '@ant-design/icons';
import './Ameyo.css';
import { axiosInstance } from 'services/axios';
import { maskPhoneNumber } from 'components/common/PhoneNumberMask';
import { store } from 'redux/store';
import moment from 'moment';

import LeadHistoryTabs from 'components/common/LeadHistoryTabs';
import EditScheduleDrawer from './schedule/EditScheduleDrawer';

import { useSelector } from 'react-redux';
import PermissionButton from './PermissionButton';

const Ameyo = (props) => {
  const { userReducer } = useSelector((state) => state);
  const { activeScheduleReportTab } = useSelector((state) => state.userReducer);

  const userId = userReducer?.userDetails?.telecaller_credentials?.telecaller_userid;
  const userPass = userReducer?.userDetails?.telecaller_credentials?.telecaller_passwd;

  const [isLoading, setIsLoading] = useState(false);
  const [isAmeyoLoading, setIsAmeyoLoading] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isCallHistoryDrawerOpen, setIsCallHistoryDrawerOpen] = useState(false);
  const [leadHistories, setLeadHistories] = useState([]);
  const [formValues, setFormValues] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isCustomerEdit, setIsCustomerEdit] = useState(false);
  const [cities, setCities] = useState(userReducer?.userDetails?.cities);
  const [isScheduleDrawer, setIsScheduleDrawer] = useState(false);
  const [loadingSchedule, setLoadingSchedule] = useState(false);
  const [ameyoCallHistoryParam, setAmeyoCallHistoryParam] = useState(null);
  const [bussinessModals, setBussinessModals] = useState([]);
  const [locationHubs, setLocationHubs] = useState([]);
  const [initialScheduleFormData, setInitialScheduleFormData] = useState({});
  const [oldScheduleFormData, setOldScheduleFormData] = useState(null);
  const [isGlobalCalling, setIsGlobalCalling] = useState(false);
  const [form] = Form.useForm();
  const [assignees, setAssignees] = useState([])
  const [callTypeStatus, setCallTypeStatus] = useState(null)
  const [isCalling, setIsCalling] = useState(false)
  const [leadType, setLeadType] = useState(null);
  const [activeLeadId, setActiveLeadId] = useState(null);
  const [activeTab, setActiveTab] = useState('1');
  const [customer, setCustomer] = useState({});
  const [schedule, setSchedule] = useState(null);
  const [driver, setDriver] = useState(null);
  const [isLoadingDriver, setIsLoadingDriver] = useState(false);
  const [campaignName, setCampaignName] = useState(null);
  const [moduleName, setModuleName] = useState('')

  const canEditAssigneeField = useMemo(() => {
    return userReducer?.userDetails?.roles?.filter((role) => !["Tele Caller", "Scheduler"].includes(role.name))?.length > 0
  }, [userReducer?.userDetails]);

  useEffect(() => {
    const { hash } = window.location;

    if (hash.includes('lead/rejoining')) {
      setLeadType('rejoining');
    } else if (hash.includes('lead')) {
      setLeadType('lead');
    }
  }, [userReducer?.userDetails, window.location.hash])

  const customShowCrm = (phone, additionalParams, requestId) => {
    var newAddParam = JSON.parse(additionalParams);

    // Submitting details here...
    fetch(`${process.env.REACT_APP_CRM_API_URL}/lead/call/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(newAddParam)
    })
      .then((response) => response.json())
      .then((data) => console.log("Success:", data))
      .catch((error) => console.error("Error:", error));

    console.log("Executed");

    // Storing phone number for reload history page on call dispose here...
    window.currentPhoneNumber = newAddParam?.displayPhone
    window.campaignId = newAddParam?.campaignId

    // Getting call history data here...
    if (newAddParam?.displayPhone && !window.isCallHistoryDrawerOpen) {
      setActiveTab('1');
      setIsLoading(true);

      // Fetching campaign details here...
      fetchCampaign(newAddParam?.campaignId);

      // Fetching call history details...
      fetchCallHistory(newAddParam?.displayPhone, newAddParam?.campaignId);

      setIsCallHistoryDrawerOpen(true)
      // Opening Ameyo modal on inComing call here..
      handleAmeyo();
    }

    // Checking call type here for display current call status...
    setIsCalling(true)

    if (newAddParam?.callType.includes('outbound')) {
      setCallTypeStatus('Outgoing')
    } else {
      setCallTypeStatus('Incoming')
    }

    var crmPage = document.getElementById('crmPage');
    var html = "Sending request to get CRM data for phone: " + phone
      + " Additional Parameters" + additionalParams
      + "<br> Recieving Response.."
      + "<br> Populating CRM data on the basis of response.."
      + "<br>Done";
    crmPage.innerHTML = html + "<br>" + crmPage.innerHTML;
    window.ameyo.integration.api.setRecordInfoForShowCrm(requestId, requestId, phone,
      setRecordForShowCrmCallback);
  }

  const fetchCampaign = async (ameyo_campaign_id) => {
    try {
      const params = { ameyo_campaign_id };

      const response = await axiosInstance.get('lead/campaign/details/', { params });
      setCampaignName(response.data?.name);
    } catch (error) {}
  }

  const fetchCallHistory = async (phone, ameyo_campaign_id = null) => {
    try {
      const params = { phone };
      if (ameyo_campaign_id) params.ameyo_campaign_id = ameyo_campaign_id;
      setAmeyoCallHistoryParam(params);

      const historyResponse = await axiosInstance.get('lead/callhistory/view/', { params });
      const customerResponse = await axiosInstance.get('lead/view/', { params });

      setIsLoading(false);
      setLeadHistories(historyResponse.data);
      setCustomer(customerResponse.data)
    } catch (error) {
      setIsLoading(false);
      setCustomer({})
    }
  }

  const initializeCRMUI = () => {
    const crmPage = document.getElementById('crmPage');
    const html = "UI initialization ";
    crmPage.innerHTML = html + "<br>" + crmPage.innerHTML;
  }

  const handleLoginStatus = (status) => {
    if (status == "loggedIn") {
      document.getElementById('ameyoLoginButton').style.display = 'none';
      window.initialize = false;
      window.isCallHistoryDrawerOpen = false;
    } else {
      document.getElementById('ameyoLoginButton').style.display = 'block';
    }

    var crmPage = document.getElementById('crmPage');
    var html = "handleLoginStatus : " + status;
    crmPage.innerHTML = html + "<br>" + crmPage.innerHTML;
  }

  const handleForceLogin = (reason) => {
    if (reason == "success") {
      window.initialize = false;
    }

    var crmPage = document.getElementById('crmPage');
    var html = "handleForceLogin : " + reason;
    crmPage.innerHTML = html + "<br>" + crmPage.innerHTML;
  }

  function handleLogout(reason) {
    // Displaying login button after logout...
    if (reason == "success") document.getElementById('ameyoLoginButton').style.display = 'block';

    var crmPage = document.getElementById('crmPage');
    var html = "Logged out : " + reason;
    crmPage.innerHTML = html + "<br>" + crmPage.innerHTML;
  }

  const customCallDispose = (userCustomerCRTInfo) => {
    // Fetching call history...
    setTimeout(() => {
      fetchCallHistory(window.currentPhoneNumber, window.campaignId);
      setIsCalling(false)
    }, 500);

    const crmPage = document.getElementById('crmPage');
    var html = "Call disposed <br> User CRT info : "
      + userCustomerCRTInfo.userCrtObjectId + "<br> Customer CRT Info : "
      + userCustomerCRTInfo.customerCrtObjectId
    crmPage.innerHTML = html + "<br>" + crmPage.innerHTML;
  }

  const initialise = () => {
    setIsAmeyoLoading(true);
    const crmOrigin = `${window.location.origin}`
    const iframeDiv = document.getElementById("ameyoIframeDiv");
    const iframeUrl = `${window?.ameyoBaseUrl}ameyowebaccess/toolbar-crm.htm?origin=${crmOrigin}`;
    const iframeHtml = `<iframe height="450px" width="398px" id="ameyoIframe" src="${iframeUrl}" allow="microphone *"></iframe>`;

    if (iframeDiv) {
      iframeDiv.innerHTML = iframeHtml;
      const iframe = document.getElementById("ameyoIframe");

      if (iframe) {
        iframe.onload = () => {
          if (document.querySelector('.ant-modal-wrap'))
            document.querySelector('.ant-modal-wrap').style.position = 'inherit'

          setTimeout(() => {
            setIsAmeyoLoading(false);
          }, 3000);
        };
      }
    }
  }

  useEffect(() => {
    if (props.isAmeyoOpen) {
      initialise();

      if (typeof window.ameyo !== 'undefined') {
        customIntegration.showCrm = customShowCrm;
        customIntegration.intializeUI = initializeCRMUI;
        customIntegration.loginStatusHandler = handleLoginStatus;
        customIntegration.forceLoginHandler = handleForceLogin;
        customIntegration.logoutHandler = handleLogout;
        customIntegration.disposeCall = customCallDispose;

        window.ameyo.integration.registerCustomFunction("showCrm", customIntegration);
        window.ameyo.integration.registerCustomFunction("intializeUI", customIntegration);
        window.ameyo.integration.registerCustomFunction("loginStatusHandler", customIntegration);
        window.ameyo.integration.registerCustomFunction("forceLoginHandler", customIntegration);
        window.ameyo.integration.registerCustomFunction("logoutHandler", customIntegration);
        window.ameyo.integration.registerCustomFunction("disposeCall", customIntegration);
      }
    }

  }, [props.isAmeyoOpen]);

  const toggleMinimize = (e) => {
    setIsMinimized(!isMinimized);

    document.querySelector('body').style.overflowY = 'auto'
    const modalWrap = document.querySelector('.ant-modal-wrap');

    if (modalWrap) {
      if (!isMinimized) {
        modalWrap.style.zIndex = 0;
        modalWrap.style.position = 'inherit';
        document.getElementById('ameyoLoginButton').style.display = 'none';
      } else {
        modalWrap.style.zIndex = 1000
      }
    }
  };

  const handleAmeyo = () => {
    props.setIsAmeyoOpen(true);
    setIsMinimized(false);

    document.querySelector('body').style.overflowY = 'auto'
    if (document.getElementById('crmPage')) document.getElementById('crmPage').innerHTML = '';

    // Show Ameyo Login button if not logged in
    if (window.initialize) {
      document.getElementById('ameyoLoginButton').style.display = 'block';
    }
  }

  const handleAmeyoCustomLogin = () => {
    setIsAmeyoLoading(true);

    if (userId && userPass) {
      window.ameyo.integration.doLogin(
        userId?.toString(),
        userPass?.toString()
      );

      document.getElementById('ameyoLoginButton').style.display = 'none';
      setTimeout(() => {
        setIsAmeyoLoading(false);
      }, 2000);
    } else {
      console.error('Invalid telecaller credentials!')
    }
  }

  const formatDateTime = (datetimeStr) => {
    if (!datetimeStr) return null;
    const date = new Date(datetimeStr);
    const formattedDate = date.toLocaleDateString('en-CA');
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedTime = date.toLocaleTimeString('en-US', options);
    return `${formattedDate} ${formattedTime}`;
  };

  const openCallHistoryModal = async (leadId, phone) => {
    setIsCallHistoryDrawerOpen(true)
    setIsCalling(false)
    
    if (leadId && !phone) {
      setIsLoading(true)
      setActiveLeadId(leadId)
      const historyResponse = await axiosInstance.get(`/lead/callhistory/view/?lead=${leadId}`);
      const customerResponse = await axiosInstance.get(`/lead/view/?lead=${leadId}`);

      setLeadHistories(historyResponse.data);
      setCustomer(customerResponse.data)
      setActiveTab('1');
      setIsLoading(false)
    }

    // Calling
    if (leadId && phone) {
      setIsLoading(true)
      setTimeout(() => {
        console.log('Calling to from Ameyo:', Number(phone));
        window.ameyo.integration.doDial(phone, null, null, null, null, null);

        // Reset the button attributes after the call is initiated
        const callHistoryButton = document.getElementById('callHistoryButton');
        if (callHistoryButton) {
          callHistoryButton.setAttribute('data-leadid', '');
          callHistoryButton.setAttribute('data-phone', '');
        }
      }, 3000);
    }
  };

  const onCloseCallHistoryModal = () => {
    setIsCallHistoryDrawerOpen(false)
    setActiveLeadId(null)
    setCustomer(null)
    setSchedule(null)
    setDriver(null)
    setAmeyoCallHistoryParam(null)
    setLeadHistories([]);
    setModuleName('')
  };

  // Handling global calling of schedule and edit customer from schedule-report and lead page...
  useEffect(() => {
    const handleCustomEditEvent = (event) => {
      const { rowData, type, isGlobalCalling } = event.detail;
      handleCustomerEdit(rowData, type, isGlobalCalling, event.detail?.module);
    };

    window.addEventListener('handleCustomerAndScheduleEvent', handleCustomEditEvent);

    return () => {
      window.removeEventListener('handleCustomerAndScheduleEvent', handleCustomEditEvent);
    };
  }, []);

  const fetchAssignees = async () => {
    const params = {
      city: parseInt(localStorage.getItem("userDefaultCity"))
    }

    const query = new URLSearchParams(params);

    await axiosInstance.get(`/users/assignees/?${query.toString()}`)
      .then((response) => {
        setAssignees(response.data)
      })
      .catch((error) => {
        notification.error({
          message: "Couldn't load assignees!"
        });
      })
  }

  const handleCustomerEdit = async (customer, type = '', isGlobalCalling = false, isModule = '') => {
    setIsGlobalCalling(isGlobalCalling);

    if (type === 'customer') {
      let selectedCity = Number(localStorage.getItem('userDefaultCity'));
      // const cityResp = await axiosInstance.get('/ameyo/city/');

      const cities = JSON.parse(localStorage.getItem('cities'))

      const filterCity = cities?.filter((city) => city.id == selectedCity)

      setCities(filterCity);
      setFormErrors({});

      const { id, first_name, last_name, phone, campaign, location, assignee } = customer;

      fetchAssignees();

      const details = {
        id,
        first_name,
        last_name,
        phone,
        campaign,
        location,
        assignee: assignee?.id
      };

      setIsCustomerEdit(true);
      setIsCallHistoryDrawerOpen(false)
      setFormValues(details)
      // setCustomScheduleData(customer)
    }
    if (type === 'schedule') {
      setIsCallHistoryDrawerOpen(false);
      onScheduleEditAction(customer, true);

      if (isModule === 'schedule-report') {
        setCustomer(customer)
        setModuleName(isModule)
      }

      setTimeout(() => {
        setFormFields(customer);
        setIsScheduleDrawer(true);

        // Holding data for the validate that is changed or not while saving...
        setOldScheduleFormData(customer);
        setInitialScheduleFormData(customer)
      }, 0);
    }
  };

  // Handleing customer update...
  const onChange = (e) => {
    const { name, value } = e.target;

    // Validation on phone input
    if (name === 'phone' && value) {
      if (!/^[1-9]\d{0,9}$/.test(value)) return;
    }

    setFormValues(prev => ({ ...prev, [name]: value }));
  };

  const onChangeSelect = (field, value) => {
    setFormValues(prev => ({ ...prev, [field]: (field == 'city') ? value?.toString() : value }));
  };

  const handleViewHistory = (customer) => {
    setIsCustomerEdit(false);
    setIsCallHistoryDrawerOpen(true)
  };

  const onCloseModal = () => {
    setIsCustomerEdit(false)
    if (!isGlobalCalling) setIsCallHistoryDrawerOpen(true)
  };

  const validateForm = (formData) => {
    const errors = {};

    // Phone number validation
    const phone = formData.phone;
    if (phone) {
      const phonePattern = /^[1-9]\d{9}$/;

      if (!phonePattern.test(phone)) {
        errors.phone = "The phone number must be 10 digits!";
      }
    } else {
      errors.phone = "This field is required!";
    }

    // Required field validation
    const requiredFields = ['first_name', 'last_name'];

    requiredFields.forEach(field => {
      if (!formData[field]) {
        errors[field] = "This field is required!";
      }
    });

    // Set errors in state
    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleUpdate = async () => {
    if (validateForm(formValues)) {
      setIsLoading(true);

      const data = {
        ...formValues,
        campaign: formValues?.campaign?.id
      }

      await axiosInstance.put(`/lead/${data?.id}/`, data).then((response) => {

        notification.success({
          message: `Customer updated successfully!`
        });

        // Resettings states here...
        setCustomer(response?.data);
        setIsCustomerEdit(false);
        setIsLoading(false);
        if (!isGlobalCalling) setIsCallHistoryDrawerOpen(true)

        // This is for updating schedule report row from here...
        window.dispatchEvent(new CustomEvent('updateCustomerRowAfterUpdate'));
      }).catch((error) => {
        setIsLoading(false);
        notification.error({ message: `Couldn't updated customer!` });
      })
    }
  }

  // Handle schedule ...
  const onScheduleEditAction = async () => {
    setFormErrors({});
    setLoadingSchedule(true);

    try {
      // Fetching business and location data
      const query = {
        permission: "optional",
        city: parseInt(localStorage.getItem("userDefaultCity") || cities[0])
      }

      const [businessResponse, locationResponse] = await Promise.all([
        axiosInstance.get(`/lead/businessmodals/?permission=optional`),
        axiosInstance.get(`/lead/locationhubs/?${new URLSearchParams(query).toString()}`),
      ]);

      setBussinessModals(businessResponse?.data?.results);
      setLocationHubs(locationResponse?.data?.results);
      setLoadingSchedule(false);
    } catch (error) {
      setLoadingSchedule(false);
      notification.error({
        message: "Couldn't load details!",
      });
    }

  };

  const onCloseSchedule = () => {
    setFormErrors({});
    setIsScheduleDrawer(false);
    if (!isGlobalCalling) setIsCallHistoryDrawerOpen(true);
  };

  const validateScheduleForm = () => {
    const requiredFields = [
      { field: 'date', message: 'Please select a date' },
      { field: 'timeslot', message: 'Please select a time slot' },
      { field: 'location_hub', message: 'Please select a location' },
      { field: 'vehicle_type', message: 'Please select a vehicle type' },
      { field: 'businessmodal', message: 'Please select a business modal' },
    ];

    const errors = requiredFields.reduce((acc, { field, message }) => {
      if (!initialScheduleFormData?.[field]) {
        acc[field] = message;
      }
      return acc;
    }, {});


    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onSaveSchedule = async () => {
    if (validateScheduleForm()) {
      // Checking formdata is changed or not here..
      if (!hasChanges()) {
        notification.info({
          message: 'No changes were made to the schedule.',
        });

        return;
      }

      setLoadingSchedule(true);

      try {
        // Prepare the data for submission
        const updatedScheduleData = getFormFieldsData(initialScheduleFormData);
        
        updatedScheduleData.date = moment(updatedScheduleData.date).format('YYYY-MM-DD');

        if (!schedule && moduleName === '') {
          updatedScheduleData.lead = customer?.id;
        }
        
        const response = await axiosInstance.post('/lead/schedule/', updatedScheduleData);

        setLoadingSchedule(false);
        setIsScheduleDrawer(false);
        if (!isGlobalCalling) setIsCallHistoryDrawerOpen(true);

        notification.success({ message: `Schedule updated successfully!` });
        console.clear();
        
        // This is for updating schedule report row from here...
        window.dispatchEvent(new CustomEvent('updateScheduleRowAfterUpdate', {
          detail: { activeTab: activeScheduleReportTab }
        }));

        // Reset the form after successful submission
        setInitialScheduleFormData(response?.data);
        setSchedule(response?.data);

      } catch (error) {
        setLoadingSchedule(false);
        // Handling errors here...
        let message = "Couldn't save the schedule. Please try again.";
        const errorData = error.response?.data;
        const field = errorData?.date || errorData?.timeslot || errorData?.location_hub || errorData?.remarks;

        if (field) message = field;
        notification.error({ message });
      }
    }
  };

  const hasChanges = () => {
    // Compare the current form data with the initial form data
    return JSON.stringify(initialScheduleFormData) !== JSON.stringify(oldScheduleFormData);
  };

  const onChangeScheduleFields = (value, name) => {
    setInitialScheduleFormData(prev => ({ ...prev, [name]: value?.toString() }));
  };

  const getFormFieldsData = (data) => ({
    date: data?.date ? moment(data?.date.toString()) : null,
    timeslot: data?.timeslot,
    location_hub: data?.location_hub?.id ? data?.location_hub.id : data?.location_hub,
    vehicle_type: data?.vehicle_type.toLowerCase(),
    businessmodal: data?.businessmodal?.id ? data?.businessmodal.id : data?.businessmodal,
    remarks: data?.remarks,
    lead: data?.lead_id,
    id: data?.id,
  });

  const setFormFields = (data) => {
    form.setFieldsValue(getFormFieldsData(data));
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (activeTab === '1' && isCallHistoryDrawerOpen) {
      fetchCustomerDetails();
    } else if (activeTab === '2') {
      fetchScheduleDetails();
    } else if (activeTab === '3') {
      fetchDriverDetails();
    }
  }, [activeTab]);

  const fetchCustomerDetails = async () => {
    setIsLoading(true)
    try {
      let params = ameyoCallHistoryParam ? ameyoCallHistoryParam : { lead: activeLeadId };
      const response = await axiosInstance.get('lead/view/', { params });
      setCustomer(response.data);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('Failed to fetch customer details:', error);
    }
  };

  const fetchScheduleDetails = async () => {
    setLoadingSchedule(true)
    try {
      let params = ameyoCallHistoryParam ? ameyoCallHistoryParam : { lead: activeLeadId };
      const response = await axiosInstance.get('lead/schedule/view/', { params });
      setSchedule(response.data);

      setInitialScheduleFormData(response.data)
      // Holding for validate
      setOldScheduleFormData(response.data)
      setLoadingSchedule(false)
    } catch (error) {
      setLoadingSchedule(false)
      console.error('Failed to fetch schedule details:', error);
    }
  };

  const fetchDriverDetails = async () => {
    setIsLoadingDriver(true)
    try {
      let params = ameyoCallHistoryParam ? ameyoCallHistoryParam : { lead: activeLeadId };
      const response = await axiosInstance.get('lead/driver/', { params });
      setDriver(response.data);
      setIsLoadingDriver(false)
    } catch (error) {
      setIsLoadingDriver(false)
      console.error('Failed to fetch driver details:', error);
    }
  };

  return (
    <>
      {/* {userPermissions?.includes('can_call_lead') && ( */}
      <div style={{ zIndex: '99999999', display: 'flex' }}>
        {userId && userPass ?
          <PermissionButton
            style={{ top: '1px', right: '40px', border: 'none', fontWeight: '500' }}
            type="default"
            className="ant-btn ameyoButton"
            icon={<CustomerServiceOutlined style={{ display: 'inline-flex' }} />}
            onClick={handleAmeyo}
            id='ameyoButton'
            permissions={['can_call_lead']}
          >Phone</PermissionButton>
          : ''}

        <Button id="ameyoLoginButton" style={{ top: '1px', right: '40px', border: 'none', fontWeight: '500', display: 'none' }} className="ant-btn ameyoButton" onClick={handleAmeyoCustomLogin}>
          <LoginOutlined style={{ display: 'inline-flex' }} /> Ameyo Login
        </Button>

        {/* Call history button */}
        <Button
          id="callHistoryButton"
          style={{ top: '1px', right: '40px', border: 'none', fontWeight: '500', display: 'none' }}
          className="ant-btn ameyoButton"
          onClick={(e) => openCallHistoryModal(e.target.getAttribute('data-leadid'), e.target.getAttribute('data-phone'))}
          data-leadid=""
          data-phone=""
        >
          <CustomerServiceOutlined style={{ display: 'inline-flex' }} /> Call History
        </Button>
      </div>
      {/* )} */}

      <Modal
        title={
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
            <span>Ameyo</span>
            <Button
              icon={isMinimized ? <PlusOutlined /> : <MinusOutlined />}
              onClick={(e) => toggleMinimize(e)}
              size="small"
            />
          </div>
        }
        open={props.isAmeyoOpen}
        onOk={() => props.setIsAmeyoOpen(false)}
        onCancel={() => props.setIsAmeyoOpen(false)}
        style={{
          position: "fixed",
          bottom: 42,
          left: 0,
          height: isMinimized ? '0px' : 'auto',
          overflow: 'hidden',
          border: '1px solid rgb(240 240 240)',
          borderRadius: '8px 8px 0 0',
          zIndex: 999999
        }}
        width={isMinimized ? '0px' : '448px'}
        footer={null}
        closable={false}
        maskClosable={false}
        mask={false}
        className={isMinimized ? 'ameyo-modal minimized' : 'ameyo-modal'}
      >
        {(isAmeyoLoading || isLoading) && (
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <Spin />
          </div>
        )}
        <div id="ameyoIframeDiv" style={{ display: isMinimized ? 'none' : 'block' }} />
        <div id="crmPage" align="middle" style={{ overflow: 'auto', height: '0px' }} />
      </Modal>

      {/* Lead history drawer */}
      <div id="leadHistory">
        <Drawer
          title="Lead History"
          open={isCallHistoryDrawerOpen}
          onClose={onCloseCallHistoryModal}
          size="medium"
          mask={false}
          id="leadHistoryDrawer"
          width={425}
        >
          {(isLoading || loadingSchedule || isLoadingDriver) && (
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Spin />
            </div>
          )}
          {!isLoading && isCalling ?
            <>
              
              <fieldset style={{ padding: "10px", borderColor: "#c31c1c", color: '#c31c1c' }}>
                <strong>Call in Progress: </strong>
                <div className='row' style={{ fontSize: "14px", display: 'flex' }}>
                  <div className='row'>
                    <div className='col-md-6'>
                      <b>Phone: </b>
                    </div>
                    <div className='col-md-6'>
                      <span>{window.currentPhoneNumber} <b>{callTypeStatus ? `(${callTypeStatus})` : null}</b></span>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <b>Campaign: </b>
                    </div>
                    <div className='col-md-6'>
                      <span>{campaignName}</span>
                    </div>
                  </div>
                </div>
              </fieldset>
              <hr />
            </>
            : ''}
          {(!isLoading || !loadingSchedule || !isLoadingDriver) && customer ? (
            <LeadHistoryTabs
              customer={customer}
              schedule={schedule}
              driver={driver}
              handleCustomerEdit={handleCustomerEdit}
              onCloseSchedule={onCloseSchedule}
              onSaveSchedule={onSaveSchedule}
              onChangeScheduleFields={onChangeScheduleFields}
              isScheduleDrawer={isScheduleDrawer}
              bussinessModals={bussinessModals}
              locationHubs={locationHubs}
              loadingSchedule={loadingSchedule}
              formErrors={formErrors}
              ScheduleFormData={initialScheduleFormData}
              isCallHistoryDrawerOpen={isCallHistoryDrawerOpen}
              leadType={leadType}
              handleTabChange={handleTabChange}
              isLoadingDriver={isLoadingDriver}
              isLoading={isLoading}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              {/* <p>No record found.</p> */}
            </div>
          )}

          {!isLoading && leadHistories?.length ? (
            leadHistories?.map((history, index) => {
              return (
                <React.Fragment key={history.id || index}>
                  <fieldset style={{ padding: "10px", background: "aliceblue" }}>
                    <legend>{formatDateTime(history.call_start_time)}</legend>
                    <div>
                      <b>Call Type: </b>
                      <span>{history.call_type}</span>
                    </div>
                    {(history?.duration !== 0) && <div>
                      <b>Call Duration: </b>
                      <span>{history?.duration}s</span>
                    </div>}
                    <div>
                      <b>Status: </b>
                      <span>
                        {history.status?.label === 'Connected' ? (
                          <CheckOutlined style={{ color: 'green', fontSize: '15px' }} title={history.status?.label} />
                        ) : history.status?.label === 'Not Connected' ? (
                          <CloseOutlined style={{ color: 'red', fontSize: '15px' }} title={history.status?.label} />
                        ) : (
                          history.status?.label
                        )}
                      </span>
                    </div>
                    <div>
                      <b>Disposition: </b>
                      <span>{history?.disposition?.name}{history?.subdisposition?.name ? ` -> ${history?.subdisposition?.name}` : ''}</span>
                    </div>
                    {(history?.remarks) && <div>
                      <b>Remarks: </b>
                      <span>{history?.remarks}</span>
                    </div>}
                    {history.call_back_time &&
                      <div>
                        <b>Call Back Time: </b>
                        <span>{formatDateTime(history.call_back_time)}</span>
                      </div>}
                    {history?.telecaller_name &&
                      <div>
                        <b>Telecaller: </b>
                        <span>{history?.telecaller_name}</span>
                      </div>
                    }
                  </fieldset>
                </React.Fragment>
              )

            })
          ) : (
            <div style={{ textAlign: "center" }}>
              {/* <p>No record found.</p> */}
            </div>
          )}
        </Drawer>
      </div>

      {/* Edit Schedule Details */}
      <EditScheduleDrawer
        onCloseSchedule={onCloseSchedule}
        isScheduleDrawer={isScheduleDrawer}
        loadingSchedule={loadingSchedule}
        ScheduleFormData={initialScheduleFormData}
        formErrors={formErrors}
        onChangeScheduleFields={onChangeScheduleFields}
        locationHubs={locationHubs}
        bussinessModals={bussinessModals}
        onSaveSchedule={onSaveSchedule}
        form={form}
        customer={customer}
      />
      {/* Edit Customer details */}
      <Drawer
        title={'Edit Customers'}
        open={isCustomerEdit}
        onClose={onCloseModal}
        footer={null}
        mask={false}
      >
        {/* Handle view history */}
        {!isGlobalCalling &&
          <Button title='View History' type="default" shape="round" icon={<HistoryOutlined />} onClick={() => handleViewHistory(leadHistories)} style={{ left: '80%', color: '#5ba3e8', borderColor: '#5ba3e8' }}></Button>
        }

        <Form layout="vertical">
          <Form.Item
            label="First Name *"
            validateStatus={formErrors?.first_name ? "error" : null}
            help={formErrors?.first_name}
          >
            <Input
              placeholder="Please enter the first name."
              name="first_name"
              value={formValues.first_name}
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item
            label="Last Name *"
            validateStatus={formErrors?.last_name ? "error" : null}
            help={formErrors?.last_name}
          >
            <Input
              placeholder="Please enter the last name."
              name="last_name"
              value={formValues.last_name}
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item
            label="Phone *"
            validateStatus={formErrors?.phone ? "error" : null}
            help={formErrors?.phone}
          >
            <Input
              placeholder="Please enter the phone."
              name="phone"
              value={formValues.phone}
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item
            label="Campaign *"
            validateStatus={formErrors?.campaign ? "error" : null}
            help={formErrors?.campaign}
          >
            <Input
              placeholder="Please enter the phone."
              name="campaign"
              value={formValues.campaign?.name}
              disabled
            />
          </Form.Item>

          <Form.Item
            label="Assignee *"
            validateStatus={formErrors?.assignee ? "error" : null}
            help={formErrors?.assignee}
          >
            <Select
              showSearch
              optionFilterProp="label"
              placeholder="Select a assignee"
              onChange={(value) => onChangeSelect('assignee', value)}
              value={formValues.assignee?.toString()}
              filterOption={(input, option) => {
                if (option.value) {
                  return option.children.map((o) => o.toLowerCase()).join("").includes(input?.toLowerCase())
                }
              }}
              disabled={!canEditAssigneeField}
            >
              <Option style={{ color: "#bfbfbf", fontWeight: "normal" }} value="" key={new Date().toISOString()}>
                Select a assignee
              </Option>
              {assignees.map((assignee, index) => (
                <Option key={index} value={assignee?.id?.toString()}>
                  {assignee.first_name} {assignee.last_name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Location"
            validateStatus={formErrors?.location ? "error" : null}
            help={formErrors?.location}
          >
            <Input
              placeholder="Please enter the location."
              name="location"
              value={formValues.location}
              onChange={onChange}
            />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button
                type="primary"
                shape="round"
                icon={formValues?.id ? <EditOutlined /> : <PlusOutlined />}
                onClick={handleUpdate}
                style={{ display: "flex", alignItems: "center" }}
                loading={isLoading}
              >
                {"Update"}
              </Button>

              <Button
                type="danger"
                shape="round"
                icon={<CloseOutlined />}
                onClick={onCloseModal}
                style={{ display: "flex", alignItems: "center" }}
              >
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default Ameyo;
