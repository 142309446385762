/* eslint-disable max-len */
import {
  Button, Drawer, Popover, Select,
} from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { store } from 'redux/store';
import actions from 'redux/user/actionTypes';
import { userLogout, userLoad } from 'redux/user/actions';

import { useTeamContext } from 'context/teamIDContext';

import useWindowSize from 'hooks/useResize';

import UpdatePassModal from 'lib/updatePassModal';
import WeekCalender from '../AppHeader/weekCalender';
import DayCalender from './dayCalender';
import Sidebar from '../sidebar';

import logo from '../../../assets/images/everestFleetLogo.png';

const Header = ({ logout, loadCurrentAccount }) => {
  const history = useHistory();
  const windowSize = useWindowSize();

  const { userReducer } = store.getState();
  const {
    name, team, partner,
    loc, manager, city, teamInfo, management, permissions,
  } = userReducer;

  const {
    setTeamID, setPartnerID, setLocID, managerID,
    setManagerID, setCityID, selectedDashboard, setSelectedDashboard,
  } = useTeamContext();

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [isPassModalOpen, setPassModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTeamID({ teamIDValue: Number(team) });
    setPartnerID({ partnerIDValue: Number(partner) });
    setLocID({ locIDValue: Number(loc) });
    setManagerID({ managerIDValue: Number(manager) });
    setCityID({ cityIDValue: Number(city) });
  }, [team, partner, loc, manager, city]);

  const managerDetails = teamInfo?.managers?.filter((item) => (
    Number(item?.id) === Number(managerID.managerIDValue)
  ));

  useEffect(() => {
    if (selectedDashboard === 'Audit Dashboard') {
      history.push('/checkIn/checkIn');
    } if (selectedDashboard === 'Recovery Dashboard') {
      history.push('/recoveryDash');
    } if (selectedDashboard === 'Onboarding Dashboard') {
      history.push('/onboardDriver');
    }
  }, [selectedDashboard]);

  const onSelectDashboard = (dashboard) => {
    setSelectedDashboard(dashboard);
    if (selectedDashboard !== dashboard) {
      store.dispatch({
        type: actions.SET_STATE,
        payload: {
          dashboard,
        },
      });
      loadCurrentAccount(); // load currentAccount on every dashboard change
      Cookies.set('dashboard', dashboard);

      if (dashboard === 'Audit Dashboard') {
        history.push('/checkIn/checkIn');
      } else if (dashboard === 'Recovery Dashboard') {
        history.push('/recoveryDash');
      } else if (dashboard === 'Onboarding Dashboard') {
        history.push('/onboardDriver');
      } else {
        history.push('/dashboard');
      }
    }
  };

  const handleSignOut = () => {
    setLoading(true);
    logout('signout');
  };

  const getBgColor = (item) => {
    if (item === selectedDashboard) return '#E6F7FF';
    return '#FFFFFF';
  };

  const showSidebar = () => {
    setSidebarOpen(true);
  };
  const onCloseSidebar = () => {
    setSidebarOpen(false);
  };

  const showProfile = () => {
    setProfileOpen(true);
  };
  const onCloseProfile = () => {
    setProfileOpen(false);
  };

  let laptop;
  if (windowSize.width < 992) {
    laptop = true;
  } else {
    laptop = false;
  }

  let tablet;
  if (windowSize.width < 768) {
    tablet = true;
  } else {
    tablet = false;
  }

  let mobile;
  if (windowSize.width < 576) {
    mobile = true;
  } else {
    mobile = false;
  }

  return (
    <div className="p-4 pb-2 bg-[#013453]">
      {isPassModalOpen && (
        <UpdatePassModal
          logout={logout}
          isModalOpen={isPassModalOpen}
          setModalOpen={setPassModalOpen}
        />
      )}
      <div className="flex justify-between items-center py-2.5">
        <div className="flex items-center sm:gap-8 gap-4">
          {laptop ? (
            <>
              <Button
                type="primary"
                icon={<MenuOutlined />}
                onClick={showSidebar}
                size="large"
              />
              <Drawer
                title={selectedDashboard?.split(' ')[0]}
                placement="left"
                open={isSidebarOpen}
                onClose={onCloseSidebar}
                width={250}
                bodyStyle={{
                  padding: 0,
                }}
              >
                <Sidebar />
              </Drawer>
            </>
          ) : null}
          <div className="flex flex-col justify-center">
            <p className="text-[#FFFFFF] font-extrabold text-xl md:text-2xl m-0">
              <span className="hidden sm:inline">Welcome</span>
              {' '}
              {name}
            </p>
            {managerDetails?.length ? (
              <div className="flex gap-2 md:mt-2.5 text-xs font-regular text-[#86A1A4]">
                <p className="mb-1 md:mb-2 md:pr-2.5 md:border-solid md:border-r-2 md:border-[#86A1A4]">
                  <span className="hidden md:inline-block">Designation:</span>
                  {' '}
                  <span className="text-[#FFFFFF]">Driver Manager</span>
                </p>
                <p className="mb-1 md:mb-2">
                  <span className="hidden md:inline-block">Branch: </span>
                  {' '}
                  <span className="text-[#FFFFFF]">{teamInfo?.city?.name}</span>
                </p>
              </div>
            ) : null}
          </div>
        </div>
        {tablet ? (
          <>
            <div className="flex items-center gap-4">
              <Button
                type="primary"
                icon={(
                  <img src="/assets/images/general/man.svg" alt="user" />
                )}
                onClick={showProfile}
                size="large"
                className="p-0 bg-transparent border-0 focus:border-0"
              />
            </div>
            <Drawer
              title={(
                <p className="font-medium text-xl m-0 pt-1">
                  {name}
                </p>
              )}
              placement="right"
              open={isProfileOpen}
              onClose={onCloseProfile}
              width={250}
              bodyStyle={{ padding: '2rem 2.5rem' }}
            >
              <div className="flex flex-col">
                {Object.keys(permissions).map((item) => (
                  <Button
                    key={item}
                    onClick={() => onSelectDashboard(item)}
                    type="button"
                    className="mb-1"
                    style={{ backgroundColor: getBgColor(item) }}
                  >
                    {item}
                  </Button>
                ))}
                <hr className="my-2" />
                <Button
                  onClick={() => setPassModalOpen(true)}
                  type="button"
                  className="text-xs my-1 hover:text-blue-400"
                >
                  Change password
                </Button>
                <Button
                  onClick={handleSignOut}
                  type="primary"
                  loading={loading}
                >
                  Sign Out
                </Button>
              </div>
            </Drawer>
          </>
        ) : (
          <div className="flex gap-4 md:flex-row flex-col-reverse">
            {management ? <DayCalender /> : <WeekCalender />}
            <Popover
              placement="bottomRight"
              title={name}
              content={(
                <div className="flex flex-col gap-2">
                  <Button
                    onClick={() => setPassModalOpen(true)}
                    type="button"
                    className="text-xs hover:text-blue-400"
                  >
                    Change password
                  </Button>
                  <Button
                    onClick={handleSignOut}
                    type="primary"
                    loading={loading}
                  >
                    Sign Out
                  </Button>
                </div>
              )}
              trigger="click"
            >
              <img
                src="/assets/images/general/man.svg"
                alt="user"
                width="40"
                className="cursor-pointer"
              />
            </Popover>
          </div>
        )}
      </div>
      <div>
        <hr className="text-[#86A1A4] my-0" />
        <div className="flex justify-between items-center my-2">
          <div className="flex items-center">
            {mobile ? (
              <Popover
                placement="bottomLeft"
                content={(
                  <p className="font-semibold text-sm m-0 p-0">
                    {teamInfo?.name}
                  </p>
                )}
                trigger="click"
                className="bg-[#013453]"
              >
                <img
                  className="rounded-full"
                  width="25"
                  src={teamInfo?.Logo ?? logo}
                  alt={teamInfo?.id}
                />
              </Popover>
            ) : (
              <>
                <img
                  className="rounded-full"
                  width="25"
                  src={teamInfo?.Logo ?? logo}
                  alt={teamInfo?.id}
                />
                <p className="text-[#37BDC8] font-semibold text-sm mb-0 ml-4">
                  {teamInfo?.name}
                </p>
              </>
            )}
          </div>
          {tablet ? (
            <div className="ml-auto">
              {management ? <DayCalender /> : <WeekCalender />}
            </div>
          ) : (
            <Select
              value={selectedDashboard}
              onChange={onSelectDashboard}
              options={Object.keys(permissions)?.map((item) => ({
                value: item,
                label: item,
              }))}
              style={{
                width: '11rem',
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: (action) => dispatch(userLogout(action)),
  loadCurrentAccount: () => dispatch(userLoad()),
});
const mapStateToProps = ({ userReducer }) => ({
  team: userReducer.team,
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
