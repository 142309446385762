/* eslint-disable */
import {
  lazy, Suspense, useEffect,
} from 'react';
import { ConnectedRouter } from 'connected-react-router';
import {
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useMenuContext } from 'context/menuContext';
import { store } from 'redux/store';
import createRoutes from 'routerUtils';
import AuthLayout from './layout';

const Router = ({ history }) => {
  const { setPath } = useMenuContext();
  const {
    userReducer: {
      permissions, dashboard,
    },
  } = store.getState();
  const routes = [
    {
      path: '/auth/sign-in',
      key: 'SIGN-IN',
      exact: true,
      Component: lazy(() => import('pages/auth/sign-in')),
    },
    {
      path: '/auth/page-not-found',
      key: 'PAGE-NOT-FOUND',
      exact: true,
      Component: lazy(() => import('pages/auth/page-not-found')),
    },
    {
      path: '/auth/unauthorized',
      key: 'UNAUTHORIZED',
      exact: true,
      Component: lazy(() => import('pages/auth/unauthorized')),
    },
    {
      path: '/dashboard',
      key: 'DASHBOARD',
      exact: true,
      Component: lazy(() => import('pages/dashboard')),
      label: 'Dashboard',
    },
    {
      path: '/admin/cities',
      key: 'CITY',
      exact: true,
      Component: lazy(() => import('pages/city')),
      label: 'CITY',
    },
    {
      path: '/admin/roles',
      key: 'ROLE',
      exact: true,
      Component: lazy(() => import('pages/role')),
      label: 'ROLE',
    },
    {
      path: '/lead/driver-walkins',
      key: 'driver-walkins',
      exact: true,
      Component: lazy(() => import('pages/driver-walkins')),
      label: 'driver-walkins',
    },
    {
      path: '/admin/users',
      key: 'USERS',
      exact: true,
      Component: lazy(() => import('pages/users')),
      label: 'USERS',
    },
    {
      path: '/lead',
      key: 'LEAD',
      exact: true,
      Component: lazy(() => import('pages/lead')),
      label: 'LEAD',
    },
    {
      path: '/lead/import-lead-summary',
      key: 'IMPORT_LEAD_SUMMARY',
      exact: true,
      Component: lazy(() => import('pages/lead/ImportLeadSummary')),
      label: 'Import Lead Summary',
    },
    {
      path: '/lead/import-lead-errors',
      key: 'IMPORT_LEAD_ERROR',
      exact: true,
      Component: lazy(() => import('pages/lead/ImportLeadErrors')),
      label: 'Import Lead Error',
    },
    {
      path: '/lead/campaigns',
      key: 'CAMPAIGN',
      exact: true,
      Component: lazy(() => import('pages/campaign')),
      label: 'CAMPAIGN',
    },
    {
      path: '/admin/disposition',
      key: 'DISPOSITION',
      exact: true,
      Component: lazy(() => import('pages/disposition')),
      label: 'DISPOSITION',
    },
    {
      path: '/lead/review',
      key: 'REVIEW',
      exact: true,
      Component: lazy(() => import('pages/review')),
      label: 'REVIEW',
    },
    {
      path: '/admin/disposition-mapping',
      key: 'DISPOSITION MAPPING',
      exact: true,
      Component: lazy(() => import('pages/disposition-mapping/DispositionMapping')),
      label: 'DISPOSITION MAPPING',
    },
    {
      path: '/lead/onboard',
      key: 'ONBOARD',
      exact: true,
      Component: lazy(() => import('pages/onboard')),
      label: 'ONBOARD',
    },
    {
      path: '/report/schedule',
      key: 'SCHEDULE REPORTS',
      exact: true,
      Component: lazy(() => import('pages/schedule-reports')),
      label: 'SCHEDULE REPORTS',
    },
    {
      path: '/report/telecaller',
      key: 'TELECALLER REPORTS',
      exact: true,
      Component: lazy(() => import('pages/telecaller-reports')),
      label: 'TELECALLER REPORTS',
    }, 
    {
      path: '/report/conversion',
      key: 'CONVERSION REPORTS',
      exact: true,
      Component: lazy(() => import('pages/conversion-reports')),
      label: 'CONVERSION REPORTS',
    }, 
    {
      path: '/lead/callhistory',
      key: 'CALL HISTORY',
      exact: true,
      Component: lazy(() => import('pages/call-history')),
      label: 'CALL HISTORY',
    },
    {
      path: '/lead/rejoining',
      key: 'LEAD REJOINING',
      exact: true,
      Component: lazy(() => import('pages/lead')),
      label: 'LEAD REJOINING',
    },
    {
      path: '/lead/followup',
      key: 'FOLLOWUP',
      exact: true,
      Component: lazy(() => import('pages/followup')),
      label: 'FOLLOWUP',
    },
  ];

  const routesList = [];
  // Pushing permissions api values of all dashboards in routesList
  Object.values(permissions || {}).forEach((array) => routesList.push(...array));
  // Pushing all created paths to routes
  routes.push(...createRoutes(routesList));
  return (
    <ConnectedRouter history={history}>
      <AuthLayout>
        <Route render={({ location }) => (
          <SwitchTransition>
            <CSSTransition
              key={location.pathname}
              appear
              timeout={300}
              classNames="fade-in"
            >
              <Switch location={location}>
                <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                {(routes || []).map(({
                  path,
                  Component,
                  label,
                  exact,
                  key,
                }) => (
                  <Route
                    path={path}
                    key={key}
                    exact={exact}
                    render={() => {
                      setPath(path);
                      return (
                        <Suspense fallback={null}>
                          <Component pageName={label} />
                        </Suspense>
                      );
                    }}
                  />
                ))}
                <Redirect to="/auth/page-not-found" />
              </Switch>
            </CSSTransition>
          </SwitchTransition>
        )}
        />
      </AuthLayout>
    </ConnectedRouter>
  );
};

export default Router;
Router.displayName = 'Router Page';
