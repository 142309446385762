import { lazy } from 'react';

// Hawkeye, Leasing, EIP, Recovery --- Dashboards
const HLERDashboardRoutes = {
  // --------------- URI'S: PATHNAMES --------------- //
  'dashboard-hawkeye,dashboard-leasing,dashboard-eip,dashboard-management': 'dashboard',
  'recovery-dash-recovery': 'recoveryDash',
  'summary-dash-recovery': 'summaryDash',
  'new-commitment-mapping-hawkeye,new-commitment-mapping-leasing': 'commitmentMapping',
  'live-report-hawkeye,live-report-leasing,live-report-eip': 'liveReport',
  'daily-report-hawkeye,daily-report-leasing,daily-report-eip': 'dailyReport',
  'weekly-report-hawkeye,weekly-report-leasing,weekly-report-eip': 'weeklyReport',
  'dead-kms-hawkeye,dead-kms-leasing,dead-km-eip': 'deadKMsReport',
  'qualification-pending-hawkeye,qualification-pending-leasing': 'qualificationPending',
  'car-recovery-report-hawkeye,car-recovery-report-leasing': 'carRecovery',
  'driver-hisaab-hawkeye,driver-hisaab-leasing,driver-hisaab-eip': 'driverHisaab',
  'driver-hisaab-leasing1,driver-hisaab-leasing2,leasing-driver-hisaab-eip,leasing-driver-hisaab-recovery': 'leasingDriverHisaab',
  'driver-hisaab-revshare1,driver-hisaab-revshare,revshare-driver-hisaab-recovery': 'revShareDriverHisaab',
  'partner-performance-leasing,partner-performance-eip': 'partnerPerformance',
  'penalty-adjustment-hawkeye,adjustment-leasing': 'penaltyAdjustment',
  'adjustments-eip': 'eipAdjustment',
  'recovery-hawkeye,recovery-leasing': 'offlineRecovery',
  'hisaab-hawkeye1,hisaab-leasing1': 'hisaabSummary',
  'os-report-hawkeye,os-report-leasing': 'osReport',
  'team-stats-hawkeye,team-stats-leasing': 'teamStats',
  'team-stats1-hawkeye,team-stats1-leasing': 'teamStatsNew',
  'weekly-compare-report-hawkeye,weekly-compare-report-leasing': 'weeklyCompareReport',
  'weekly-compare-report-eip': 'weeklyCompareEip',
  'phonepe-eip': 'phonePeEip',
  '2-hourly-report-hawkeye,two-hourly-report-leasing': 'twoHourly',
  'weekly-summary-report-hawkeye,weekly-summary-report-leasing,weekly-summary-report-eip': 'weeklySummaryNew',
  'incentive-qualification-report-hawkeye,incentive-qualification-report-leasing': 'incentive',
  'driver-rating-summary-hawkeye,driver-rating-summary-leasing': 'driverRatingSummary',
  'dead-kms-summary-hawkeye,dead-kms-summary-leasing': 'deadkmSummary',
  'nd-count-report-hawkeye,nd-count-report-leasing': 'ndCount',
  'car-parking-report-hawkeye,car-parking-report-leasing': 'carParking',
  'team-target-hawkeye,team-target-leasing': 'teamTarget',
  'car-summary-hawkeye,car-summary-leasing': 'carSummary',
  'car-allocation-leasing': 'carAllocationReport',
  'car-team-allocation-hawkeye,car-team-allocation-leasing': 'carTeamAllocation',
  'live-car-status-hawkeye,live-car-status-leasing': 'liveCarReport',
  'driver-profile-hawkeye,driver-profile-leasing': 'driverProfile',
  'incentive-plan-hawkeye': 'incentivePlan',
  'historical-data-hawkeye,historical-data-leasing,historical-data-recovery': 'historicalData',
  'documentation-charge': 'admin/documentationCharge',
  'security-deposit': 'admin/securityDeposit',
  'security-deposit-transactions-adjustments': 'admin/securityDepositTransactionsAdjustments',
  'documentation-charge-leasing': 'admin/documentationCharge',
  'security-deposit-leasing': 'admin/securityDeposit',
  'security-deposit-transactions-adjustments-leasing': 'admin/securityDepositTransactionsAdjustments',
  'car-allocation-eip': 'carAllocation',
  'reconciliation-leasing': 'reconciliationReport',
  'car-status-report-leasing': 'carStatusReport',
  'car-status-error-leasing': 'carStatusError',
  'car-model-report-leasing': 'carModelReport',
  'daily-dash-hawkeye': 'dailyDash',
};
// Management Dashboard
const ManagementDashboardRoutes = {
  // --------------- URI'S: PATHNAMES --------------- //
  // 'rev-share-performance-management': 'revSharePerformance',
  // 'rev-share-stats-management': 'revShareStats',
  // 'recovery-report-management': 'recoveryReport',
  // 'car-eligibility-report-management': 'carEligibilityReport',
  // 'leasing-stats-management': 'leasingStats',
  // 'leasing-performance-management': 'leasingPerformance',
  // 'leasing-recovery-report-management': 'leasingRecoveryDash',
  'weekly-vehicle-report-management': 'weeklyVehicleReport',
  'car-performance-till-date-management': 'carHistoryReport',
  // 'total-car-report-management': 'totalCarReport',
  // 'car-purchase-report-management': 'carPurchaseReport',
  // 'gps-report-location-wise-management': 'gpsLocationWiseReport',
  // 'gps-report-car-wise-management': 'gpsCarWiseReport',
  // 'average-utilisation-management': 'averageUtilisation',
  // 'location-stats-management': 'locationStats',
  // 'location-compare-report-management': 'locationCompareReport',
  // 'shpv-report-management': 'shpvReport',
  // 'shift-wise-driver-data-management': 'shiftWiseDriverData',
  'driver-shift-timing-management': 'driverShiftTimings',
  'driver-attrition-management': 'monthlyAttrition',
  // 'location-level-uber-stats-management': 'locationLevelUberStats',
  // 'ola-dash-management': 'olaDash',
  'recruitment-report-management': 'recruitmentReport',
  // 'weekly-recovery-report-management': 'weeklyRecoveryReport',
  // 'live-report-mgmt': 'liveReportMgmt',
  // 'eip-report-mgmt': 'eipReport',
};
// Audit Dashboard
const AuditDashboardRoutes = {
  // --------------- URI'S: PATHNAMES --------------- //
  // 'check-in-audit': 'checkIn/checkIn',
  // 'car-drop-audit': 'checkIn/dropOff',
  // 'checkin-details-list-audit': 'checkIn/checkInDetailsList',
  // 'Parking-checkin-audit': '/home/checkIn/parking',
  // 'Parking-mgmt-audit': '/home/parkingManagement/parking',
  // 'list-audit': 'checkIn/auditList',
  // 'drop-off-list-audit': 'checkIn/dropOffList',
  // 'check-out-audit': 'checkOut/checkOutForm',
  // 'check-out-list-audit': 'checkOut/checkOutList',
  // overview: 'visibilityDash/overview',
  // 'location-wise-rta-dash': 'visibilityDash/locationWiseRTA',
  // 'car-status-report': 'visibilityDash/carStatusReportAudit',
  // 'car-list-audit': 'carList',
  // 'allocation-audit': 'allocation/RTAList',
  // 'allocation-list-audit': 'allocation/allocationList',
  // 'parking-mgmt-audit': 'parkingManagement/parking',
  // 'parking-transfer-audit': 'parkingManagement/parkingTransfer',
  // 'parking-complex-audit': 'parkingManagement/parkingComplex',
  // 'parking-section-audit': 'parkingManagement/parkingSection',
  // 'parking-bay-audit': 'parkingManagement/parkingBay',
  // 'transfer-audit': 'carStatusTransfer/carStatusTransferForm',
  // 'car-transfer-list-audit': 'carStatusTransfer/carStatusTransferList',
  // 'battery-audit': 'battery/batteryForm',
  // 'battery-list-audit': 'battery/batteryList',
  // 'tyre-audit': 'tyre/tyreForm',
  // 'tyre-list-audit': 'tyre/tyreList',
  // 'bulk-data-upload': 'bulkDataUpload',
};
// Onboard Driver
const OnboardingDashboardRoutes = {
  // --------------- URI'S: PATHNAMES --------------- //
  drivers: 'onboardDriver/drivers',
  'unfilled-application': 'onboardDriver/unfilledApplication',
  'driver-onboarding': 'onboardDriver',
  // 'driver-onboarding': 'onboardDriver/onboardDriver',
  'new-application': 'onboardDriver/newApplication',
  'application-with-errors': 'onboardDriver/applicationWithErrors',
  'driver-test': 'onboardDriver/driverTest',
  'driver-training': 'onboardDriver/driverTraining',
  'driver-deposite': 'onboardDriver/deposit',
  'contract-unsigned': 'onboardDriver/contract',
  'contract-signed': 'onboardDriver/contractSignedList',
  'driver-report': 'onboardDriver/report',
};
// Jarvis
const JarvisDashboardRoutes = {
  // --------------- URI'S: PATHNAMES --------------- //
  // 'garage-uploads': 'garageReport',
  // 'reports-download': 'reportsDownload',
  // 'platform-tables-reco': 'crmReport',
  // 'payment-report': 'carManagementReport',
  'crm-download': 'crmDownload',

};

// combining all Dashboards Routes
const routerObject = {
  // ...HLERDashboardRoutes,
  ...ManagementDashboardRoutes,
  // ...AuditDashboardRoutes,
  ...OnboardingDashboardRoutes,
  ...JarvisDashboardRoutes,
};
function createRoutes(permissions) {
  // CREATING APP RELATED ROUTES w.r.t selected dashboard
  const routerList = [];
  (permissions || []).forEach((page) => {
    let pagePath = '';
    let homePath = '';
    Object.entries(routerObject).forEach(([key, val]) => {
      if (key.split(',').includes(page.uri)) {
        pagePath = val.split('/').slice(-1)[0];
        homePath = val;
      }
    });
    if (pagePath && homePath) {
      routerList.push({
        path: `/${homePath}`,
        uri: page.uri,
        key: page.uri,
        exact: true,
        label: page.name,
        Component: lazy(() => import(`pages/${pagePath}`)),
      });
    }
  });
  return (routerList);
}

export default createRoutes;
